<template>
  <div>
    <template v-if="showBearerField">
      <v-expand-transition group>
        <div key="newBearer" v-if="usingNewBearer">
          <v-alert color="secondary" outlined class="mb-0">
            <div class="flex-grow-1 d-flex align-center">
              <div class="flex-grow-1">
                <div class="text-caption">
                  Registrando Novo Titular de Dados
                </div>
                <div class="text-subtitle-1 font-weight-bold">
                  {{ consent.new_bearer.name }}
                </div>
                <div class="text-body-2">
                  {{ consent.new_bearer.email }}
                </div>
                <div class="text-body-2">
                  {{ consent.new_bearer.phone }}
                </div>
                <div class="text-body-2">
                  {{ consent.new_bearer.cpf || consent.new_bearer.cnpj }}
                </div>
              </div>
              <v-tooltip top>
                <span>
                  Remover Titular
                </span>
                <template #activator="{ on }">
                  <v-btn icon outlined v-on="on" @click="removeNewBearer()" :disabled="disabled">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </v-alert>
        </div>
        <div key="bearerSelection" v-else class="d-flex align-center">
          <ConsentField
            v-model="consent.bearer_id"
            item-text="name"
            item-value="id"
            filled
            hide-details="auto"
            label="Titular *"
            class="white"
            :disabled="disabled"
            :rules="[$rules.required]"
            endpoint="/contacts"
            style="width: 100%"
          />
          <v-btn
            color="success"
            outlined
            class="mx-2 flex-grow-1"
            @click="newBearerDialog.active = true"
          >
            Novo Titular
          </v-btn>
        </div>
      </v-expand-transition>
    </template>
    <v-text-field
      v-else-if="!hideTitle"
      v-model="consent.template_name"
      label="Titulo do Modelo *"
      hint="Coloque um título fácil para você identificar. Ele não aparecerá para titulares."
      filled
      hide-details="auto"
      :rules="[$rules.required]"
      :disabled="disabled"
      
    />
    <ConsentField
      v-model="consent.bearerInfos"
      item-text="title"
      item-value="id"
      filled
      hide-details="auto"
      label="Dados do Titular *"
      class="white mt-5"
      :disabled="disabled"
      :rules="[$rules.requiredList]"
      endpoint="/bearer_infos"
      multiple
    />
    <v-scroll-y-transition group>
      <template v-for="(purpose, index) in consent.purposes">
        <v-textarea
          v-model="consent.purposes[index]"
          :key="index"
          :label="`Finalidade ${index + 1} *`"
          item-text="text"
          item-value="id"
          filled
          rows="2"
          auto-grow
          hide-details="auto"
          class="mt-5 white white--text"
          :disabled="disabled"
          :rules="[$rules.required]"
          @focus="hints = true"
        >
          <template #append v-if="consent.purposes.length > 1">
            <v-btn
              icon
              @click="consent.purposes.splice(index, 1)"
              color="warning"
              :disabled="disabled"
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </template>
    </v-scroll-y-transition>
    <v-btn
      class="mt-2"
      color="info"
      :disabled="disabled"
      outlined
      @click="consent.purposes.push('')"
    >
      Nova Finalidade
    </v-btn>
    <!--<v-expand-transition>
            <v-card class="elevation-8 mt-3" v-if="hints">
              <v-card-title class="d-flex align-center justify-space-between">
                <div class="text-h6 font-weight-bold">Dicas</div>
                <v-btn @click="hints = false" icon
                  ><v-icon color="black">mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="4" v-for="idea in ideas" :key="idea.text">
                    <v-card
                      @click="
                        consent.purposes.push(idea.text);
                        hints = false;
                      "
                      class="pa-2"
                    >
                      {{ idea.text }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-expand-transition>-->
    <v-row no-gutters class="d-flex align-center">
      <v-col cols="6">
        <ConsentField
          v-model="consent.means_id"
          item-text="title"
          item-value="id"
          label="Forma *"
          filled
          hide-details="auto"
          class="my-5 mr-2 white white--text"
          :disabled="disabled"
          :rules="[$rules.required]"
          endpoint="/means"
        />
      </v-col>
      <v-col cols="6">
        <ConsentField
          v-model="consent.duration_id"
          item-text="name"
          item-value="id"
          label="Duração *"
          filled
          hide-details="auto"
          class="my-5 mr-2 white white--text"
          :disabled="disabled"
          :rules="[$rules.required]"
          endpoint="/durations"
          v-if="bearerIsCompany"
        />
      </v-col>
    </v-row>
    <ConsentField
      v-model="consent.controllers"
      item-text="name"
      item-value="id"
      filled
      hide-details="auto"
      label="Controladores *"
      class="white"
      :disabled="disabled"
      :rules="[$rules.requiredList]"
      endpoint="/controllers"
      multiple
      v-if="bearerIsCompany"
    />
    <ConsentField
      v-model="consent.responsibilities"
      item-text="title"
      item-value="id"
      label="Responsabilidades dos Agentes"
      filled
      hide-details="auto"
      class="my-5 white white--text"
      :disabled="disabled"
      :rules="[]"
      multiple
      endpoint="/responsibilities"
      v-if="bearerIsCompany"
    />

    <ConsentField
      v-model="consent.consequences"
      :items="consequences"
      label="Consequências de não consentimento"
      item-text="title"
      item-value="id"
      filled
      hide-details="auto"
      class="my-5 white white--text"
      :disabled="disabled"
      :rules="[]"
      multiple
      endpoint="/consequences"
    />
    <ConsentField
      v-model="consent.risks"
      :items="risks"
      item-text="title"
      item-value="id"
      label="Riscos de tratamento"
      filled
      hide-details="auto"
      class="my-5 white white--text"
      :disabled="disabled"
      :rules="[]"
      multiple
      endpoint="/risks"
    />

    <v-textarea
      v-model="consent.additional_info"
      label="Informações Adicionais" hide-details="auto"
      hint="Mais detalhes sobre o tratamento de dados que será realizado"
      :disabled="disabled"
      filled
      rows="4"
    />

    <v-dialog v-model="newBearerDialog.active" max-width="400px">
      <v-card class="pa-2">
        <v-card-title>
          Novo Titular de Dados
        </v-card-title>
        <v-form ref="newBearerForm" @submit.prevent="setNewBearer()">
          <v-card-text>
              <!-- <v-radio-group v-model="newBearerDialog.bearerType" class="mt-0 pt-0" hide-details>
                <v-radio value="cpf" label="Pessoa Física (CPF)" />
                <v-radio value="cnpj" label="Pessoa Jurídica (CNPJ)" />
              </v-radio-group> -->
              <v-text-field
                v-model="newBearerDialog.data.name"
                filled
                :label="newBearerDialog.bearerType == 'cpf' ? 'Nome Completo *' : 'Razão Social *'"
                hide-details="auto"
                class="my-5"
                :rules="[$rules.required]"
              />
              <template v-if="newBearerDialog.bearerType == 'cpf'">
                <v-text-field
                  v-model="newBearerDialog.data.cpf"
                  filled
                  label="CPF *"
                  placeholder="000.000.000-00"
                  v-mask="'###.###.###-##'"
                  hide-details="auto"
                  class="my-5"
                  :rules="[$rules.required, $rules.cpf]"
                />
              </template>
              <template v-else>
                <v-text-field
                  v-model="newBearerDialog.data.cpf"
                  filled
                  label="CNPJ *"
                  v-mask="'##.###.###/####-##'"
                  hide-details="auto"
                  class="my-5"
                  :rules="[$rules.required, $rules.cnpj]"
                />
              </template>
              <v-text-field
                v-model="newBearerDialog.data.email"
                filled
                label="E-Mail *"
                hide-details="auto"
                class="my-5"
                :rules="[$rules.required, $rules.email]"
              />
              <v-text-field
                v-model="newBearerDialog.data.phone"
                filled
                label="Telefone *"
                hide-details="auto"
                class="my-5"
                v-mask="['(##) ####-####', '(##) #####-####']"
                placeholder="(00) 00000-0000"
                :rules="[$rules.required, $rules.phone]"
              />
              <v-checkbox
                label="Estou ciente que os titulares de dados precisam consentir previamente com o cadastro de seus dados pessoais no eConsenso, bem como sobre o recebimento de comunicações por E-Mail/SMS sobre Consentimentos solicitados na plataforma eConsenso"       
                hide-details
                :rules="[$rules.required]"
              />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              @click="newBearerDialog.active = false"
            >
              Cancelar
            </v-btn>
            <v-btn color="success" type="submit">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConsentField from "@/components/ConsentField.vue";

export default {
  name: "ConsentForm",
  components: {
    ConsentField,
  },
  props: {
    value: { required: true, type: Object },
    disabled: { required: true, type: Boolean },
    showBearerField: { type: Boolean, default: false },
    hideTitle: {type: Boolean, default: false}
  },
  data() {
    return {
      consent: this.value,
      usingNewBearer: false,
      newBearerDialog: {
        active: false,
        bearerType: 'cpf',
        data: {
          name: "",
          email: "",
          cpf: "",
          cnpj: "",
          phone: "",
        },
      },
      hints: false,
      contacts: [],
      controllers: [],
      means: [],
      durations: [],
      consequences: [],
      risks: [],
    };
  },
  computed: {
    bearerIsCompany(){
      if (!this.consent.bearer){
        return true
      }
      return !!this.consent.bearer.cnpj
    }
  },
  watch: {
    consent(data) {
      this.$emit("input", data);
    },
    value(data){
      if(this.consent != data){
        this.consent = data
      }
    },
  },
  methods: {
    removeNewBearer(){
      this.consent.new_bearer = undefined
      this.usingNewBearer = false
    },
    setNewBearer(){
      if (this.$refs.newBearerForm.validate()){
        this.usingNewBearer = true
        this.newBearerDialog.active = false
        let new_bearer = this.newBearerDialog.data
        if (this.newBearerDialog.bearerType == 'cpf'){
          delete new_bearer['cnpj']
        } else {
          delete new_bearer['cpf']
        }
        this.consent.new_bearer = new_bearer
        this.consent.bearer_id = undefined
      } else {
        this.$showMessage('warning', "Preencha corretamente todos os campos")
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>