<template>
  <div>
    <v-autocomplete
      ref="field"
      v-bind="$attrs"
      :label="label"
      :value="value"
      @input="$emit('input', $event)"
      :items="items"
      :loading="loading"
      no-data-text="Nenhum registro encontrado"
      :chips="multiple"
      :deletable-chips="multiple"
      :multiple="multiple"
    >
      <template #append-item v-if="multiple">
        <div class="mt-2 mr-2 d-flex justify-end">
          <v-btn small depressed color="primary" @click="$refs.field.blur()">
            ok
          </v-btn>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "ConsentField",

  props: {
    value: { required: true, },
    label: { required: true, },
    multiple: { type: Boolean, default: false, },
    endpoint: { required: true, type: String }
  },

  data: () => ({
    items: [],
    loading: false,
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getData(){
      this.loading = true;
      this.$axios.get(this.endpoint)
      .then((response) => {
        this.items = response.data
      })
      .catch(() => {
        this.$showMessage("error", "Não foi possível carregar " + this.label.toLowerCase())
      })
      .finally(() => {
        this.loading = false;
      })
    },
  }
  
}
</script>