<template>
  <v-card elevation="4"> <!--Fazer Loading em editar-->
    <v-card class="transparent elevation-0">
      <v-card-title>
        <div class="d-flex align-center" style="width: 100%"> 
          <div class="flex-grow-1 text-h5 font-weight-medium d-flex align-center">
            Modelos de Consentimento
            <HelpDialog
              class="mx-4"
              title="Modelos de Consentimento"
              message="Preciso de ajuda para gerenciar os modelos de consentimento."
            >
              <p>
                Nesta página são gerenciados os modelos de consentimentos.
              </p>
              <p>
                Os modelos de consentimento servem para acelerar o processo de criação de solicitações de consentimentos para os titulares.
                Ao registrar um modelo, é possível utilizá-lo rapidamente na tela "Solicitar Consentimento", que já estará listado como modelo.
              </p>
              <p>
                Lembre-se de colocar um <b>bom nome</b> no modelo, dessa maneira, será mais fácil identificá-lo no momento de criação.
              </p>
            </HelpDialog>
          </div>
          <div>
            <v-btn color="success" @click="openNew()">
              Novo Modelo
            </v-btn>
          </div>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <!-- <v-card-subtitle class="py-2">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details="auto"
            class="mt-0 pt-0"
          />
        </v-card-subtitle> -->
        <v-data-table
          :headers="headers"
          :items="templates"
          :items-per-page="-1"
          :search="search"
          :loading="loading"
          loading-text="Carregando... Por favor, espere"
          hide-default-footer
          no-data-text="Nenhum controlador cadastrado"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="openTemplateDialog(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="
                deleteDialog = true;
                template_id = item.id;
              "
            >
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="newTemplateDialog"
      :persistent="loadingTemplate || loadingSave"
      max-width="900"
    >
      <v-card class="pa-2" :loading="loadingTemplate">
        <v-card-title class="d-flex align-center justify-space-between">
          <div>
            <template v-if="!templateForm.id">
              Novo Modelo
            </template>
            <template v-else>
              Edição de Modelo
            </template>
          </div>
          <div>
            <v-btn icon @click="newTemplateDialog = false" color="black" :disabled="loadingTemplate || loadingSave">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-divider class="my-3" />
        <v-form ref="form">
          <v-card-text>
            <ConsentForm v-model="templateForm" :disabled="loadingTemplate || loadingSave" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              @click="saveTemplate()"
              :disabled="loadingTemplate"
              :loading="loadingSave"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      :persistent="loadingDelete"
    >
      <v-card color="grey lighten-2">
        <v-card-title class="text-subtitle-1 d-flex justify-center">
          Tem certeza que deseja excluir esse Modelo?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="mr-1"
            text
            :disabled="loadingDelete"
            @click="deleteDialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="ml-1"
            :loading="loadingDelete"
            color="error"
            text
            @click="deleteTemplate()"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--<v-col class="">
        <v-card align="start" height="570" class="mt-16 mr-5 orange accent-2">
          <div class="text-center white--text justify-start text-h4 font-weight-medium mx-2">
            Formulários
          </div>
          <v-container>
          <v-row>
          <v-col v-for="form in forms" :key="form.title" :cols="form.cols">
          <v-card >
            <v-img
              :src="form.src"
              class="white--text align-end"
              height="200px"
            >
            <v-card-title class="black--text" v-text="form.title"/>
            </v-img>
          </v-card>
          </v-col>
          </v-row>
          </v-container>
        </v-card>
      </v-col>-->
  </v-card>
</template>

<script>
import ConsentForm from "@/components/ConsentForm.vue";
import HelpDialog from "@/components/HelpDialog.vue";

export default {
  name: "Templates",

  components: {
    ConsentForm, HelpDialog,
  },

  data: () => ({
    search: "",
    deleteDialog: false,
    newTemplateDialog: false,
    loading: false,
    loadingSave: false,
    loadingTemplate: false,
    loadingDelete: false,
    newBearer: false,
    hints: false,
    contacts: [],
    controllers: [],
    means: [],
    durations: [],
    templates: [],
    templateForm: {
      template_name: "",
      purposes: [""],
      means_id: undefined,
      duration_id: undefined,
      controllers: [],
      bearerInfos: [],
      consequences: [],
      risks: [],
      responsibilities: [],
      additional_info: "",
    },
    headers: [
      {
        text: "TItulo",
        align: "start",
        sortable: true,
        //filtereable: false,
        value: "template_name",
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    controllers: [],
  }),

  mounted() {
    this.getTemplates();
  },

  methods: {
    openNew(){
      this.templateForm = {
        template_name: "",
        purposes: [""],
        means_id: undefined,
        duration_id: undefined,
        controllers: [],
        bearerInfos: [],
        consequences: [],
        risks: [],
        responsibilities: [],
        additional_info: "",
      }
      this.newTemplateDialog = true
    },
    saveTemplate() {
      if (this.$refs.form.validate()) {
        this.loadingSave = true;
        this.loadingTemplate = true;
        let request;
        if (this.templateForm.id) {
          request = this.$axios.put(
            `/consent_request_template/${this.templateForm.id}`,
            this.templateForm
          );
        } else {
          request = this.$axios.post(
            "/consent_request_template",
            this.templateForm
          );
        }
        request
          .then((response) => {
            this.$showMessage("success", "Modelo Salvo");
            this.getTemplates();
            this.newTemplateDialog = false;
          })
          .catch((error) => {
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
            this.loadingTemplate = false;
          });
      }
    },
    deleteTemplate() {
      this.loadingDelete = true;
      this.$axios
        .delete(`/consent_request_template/${template_id}`)
        .then((response) => {
          this.$showMessage("success", "Modelo Excluido");
          this.getTemplates();
          this.deleteDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("error", "Modelo não encontrado");
              return;
            }
          }
          this.$showMessage("error", "Ocorreu um erro inesperado");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    getTemplates() {
      this.loading = true;
      this.$axios
        .get("/consent_request_template")
        .then((response) => {
          this.templates = response.data;
        })
        .catch((error) => {
          this.$showMessage(
            "error",
            "Erro Inesperado no Carregamento dos Modelos"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openTemplateDialog(template) {
      if (template) {
        this.$axios
          .get(`/consent_request_template/${template.id}`)
          .then((response) => {
            this.templateForm = response.data;
          })
          .catch((error) => {
            this.$showMessage("error", "Ocorreu um erro inesperado");
          });
      } else {
        this.templateForm = {};
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
      this.newTemplateDialog = true;
    },
  },

};
</script>
